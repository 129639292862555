import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import WhyUs from "../components/Repeating/WhyUs";
import RecentPosts from "../components/Repeating/RecentPosts";
import RanchViewLifestyle from "../components/Repeating/RanchViewLifestyle";
import Services from "../components/Repeating/Services";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";

const IndexPage = ({ data }) => {
  return (
    <Layout siteSettings={data.siteSettings} contactInfo={data.contactInfo}>
      <SearchEngineOptimization
        title="RanchView Senior Assisted Living | Encinitas, CA"
        description="RanchView (formerly Olivenhain Guest Home) is a senior assisted living & memory care facility in Encinitas, CA. Learn more about how we can help your family!"
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="relative mb-14 w-full bg-[#09363d] pt-16 md:mb-24 md:bg-transparent md:pb-[19rem] md:pt-28">
        <div className="absolute bottom-0 left-0 hidden h-full w-full md:block">
          <StaticImage
            src="../images/1.0 Homepage/1.0 hero-desktop.png"
            loading="eager"
            placeholder="none"
            className="h-full"
            imgClassName="object-top"
          />
        </div>

        <div className="container relative">
          <div className="grid gap-y-10">
            <div className="text-center md:max-w-[588px] md:text-left">
              <p className="font-heading text-[3.75rem] font-bold leading-[4rem] text-white md:text-[6.875rem] md:leading-[7.2rem] lg:mb-5">
                The good life,{" "}
                <span className="text-tertiary-200">continued.</span>
              </p>
              <p className="text-[1.5rem] font-light leading-[2.125rem] text-white md:mb-4 md:text-[2.125rem] md:leading-[2.75rem] lg:text-[2.125rem] lg:leading-[2.75rem]">
                Memory care and assisted living in a relaxed, home-like setting.
              </p>
              <ButtonSolid
                modal="modal-contact"
                altStyle={2}
                text="Contact Us"
              />
            </div>

            <div className="-mx-4 md:hidden">
              <StaticImage
                src="../images/1.0 Homepage/1.0 hero-mobile.jpg"
                loading="eager"
                placeholder="none"
                className="h-full w-full"
                imgClassName="object-top"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div>
              <h1>
                Top Alzheimer’s & Dementia Care Serving Encinitas & North
                County, CA
              </h1>
              <p>
                RanchView Senior Assisted Living is a{" "}
                <a href="https://www.ranchviewencinitas.com/dementia-care-san-diego/">
                  memory care
                </a>{" "}
                community in Olivenhain, a small community nestled between the
                coastal town of Encinitas and the suburb of Rancho Santa Fe.
                Formerly known as Olivenhain Guest Home, we’ve provided seniors
                safe, compassionate, and family-focused care for over 50 years.
                In our home setting, your loved ones can age in place gracefully
                and with dignity.
              </p>
            </div>
            <div>
              <StaticImage
                src="../images/1.0 Homepage/2.0 intro.jpg"
                alt="Top Alzheimer’s & Dementia Care Serving Encinitas & North County, CA"
                width={560}
                loading="eager"
                placeholder="none"
              />
            </div>
          </div>
        </div>
      </section>

      <Services />
      <RanchViewLifestyle hide={4} />
      <WhyUs />
      <Testimonials />
      <RecentPosts />
      <CallToAction phonenumber={data.contactInfo.phonenumber} />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Facebook.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Twitter.jpg" }
    ) {
      publicURL
    }
    sanitySiteSettings {
      title
    }

    allSanityServices {
      nodes {
        title
        _id
      }
      totalCount
    }
    contactInfo: sanityContactinfo {
      title
      description
      phonenumber
      email
      address
      license
      bookingurl
    }
    siteSettings: sanitySiteSettings {
      title
      description
      author
      headerlogo {
        asset {
          gatsbyImageData
        }
      }
      footerlogo {
        asset {
          gatsbyImageData
        }
      }
      facebookurl
      twitterurl
      privacypolicy
    }
  }
`;

export default IndexPage;
